define('due-dashboard/components/reports/due-report-table/due-report-table', ['exports', 'ember', 'due-dashboard/helpers/current-timezone', 'due-dashboard/objects/reports/due-report-table-params', 'due-dashboard/objects/reports/data-converter/table-data-converter-factory'], function (exports, _ember, _dueDashboardHelpersCurrentTimezone, _dueDashboardObjectsReportsDueReportTableParams, _dueDashboardObjectsReportsDataConverterTableDataConverterFactory) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  var observer = _ember['default'].observer;
  exports['default'] = Component.extend({
    tagName: 'div',
    classNames: ['report-array'],
    classNameBindings: ['layout'],

    allColumns: ['index', 'icon', 'main-label', 'number-trend', 'breakdown', 'positive-impact', 'negative-impact', 'label-with-sub'],
    filters: service(),
    dataQuery: service('requests/data-query'),
    currentLanguage: computed.alias('currentAccount.content.language.tag'),

    tableParams: null,
    dataConverter: null,
    displayViewBy: false,
    pagination: false,
    isLoading: true,
    isEmpty: false,
    nameModalIsOpened: false,
    exportModalIsOpened: true,
    progressModalIsOpened: false,
    emptyModalIsOpened: false,
    reload: false,
    formatOptions: ['csv', 'xlsx'],
    fileContentOptions: ['displayed_view', 'full_hierarchy'],

    pageIndex: 1,
    pageCount: 1,

    limit: { key: 10, text: '10' },
    limits: [{ key: 10, text: '10' }, { key: 25, text: '25' }, { key: 50, text: '50' }, { key: 100, text: '100' }],
    // Popups automatically triggered by array view_type field
    questionsListPopupToggle: false,
    tagsListPopupToggle: false,

    popups: {
      'questions-breakdown': 'questionsListPopupToggle',
      'tags-breakdown': 'tagsListPopupToggle'
    },

    content_data: [],

    reloadObserver: observer('reload', function () {
      this.set('content_data', []);
      this.set('pageIndex', 1);
      this.setTableParams();
      this.fetchData();
    }),

    querySegments: computed('', function () {
      var _this = this;

      if (!this.tableParams.view_by) {
        return null;
      }

      var segments = this.tableParams.segments,
          querySegments = {};
      if (this.get('currentAccount.content.profile') !== 'custom') {
        Object.entries(segments).forEach(function (_ref) {
          var _ref2 = _slicedToArray(_ref, 2);

          var segment = _ref2[0];
          var value = _ref2[1];

          querySegments[segment.toLowerCase()] = value;
        });
      } else {
        (function () {
          var hierarchy = _this.get('currentAccount.content.account_custom_profile_right.content.reports_hierarchy').map(function (h) {
            return h.toLowerCase();
          }),
              availableSegments = Object.keys(segments).filter(function (s) {
            return hierarchy.includes(s.toLowerCase());
          });
          availableSegments.forEach(function (segment) {
            querySegments[segment.toLowerCase()] = segments[segment];
          });
        })();
      }
      return querySegments;
    }),

    popupEnabled: computed('', function () {
      return Object.keys(this.get('popups')).includes(this.tableParams.view_type);
    }),

    availableExportFormats: computed('tableParams.type', function () {
      var type = this.get('tableParams.type');

      switch (type) {
        case 'tag-breakdown':
          return ['xlsx'];
        case 'segments':
          return ['xlsx'];
      }
    }),

    customExportOptions: computed('tableParams.translation', function () {
      var tag = this.get('currentLanguage');

      var opts = this.tableParams.translations[tag].headers.map(function (opt) {
        return { label: opt };
      });
      console.log(opts);
      opts.shift();
      return opts;
    }),

    init: function init() {
      this._super();
      this.setTableParams();
      this.setViewBySection();
      this.setDataConverter();
      this.fetchData();
    },

    setViewBySection: function setViewBySection() {
      var _this2 = this;

      if (!this.tableParams.view_by) {
        return;
      }

      var segments = this.tableParams.view_by.segments,
          querySegments = {};
      var viewByOpts = [];

      if (this.get('currentAccount.content.profile') !== 'custom') {
        viewByOpts = Object.keys(segments).map(function (s) {
          querySegments[s.toLowerCase()] = segments[s];
          return s.charAt(0).toUpperCase() + s.slice(1);
        });
        this.set('querySegments', querySegments);
      } else {
        (function () {
          var hierarchy = _this2.get('currentAccount.content.account_custom_profile_right.content.reports_hierarchy').map(function (h) {
            return h.toLowerCase();
          }),
              availableSegments = Object.keys(segments).filter(function (s) {
            return hierarchy.includes(s.toLowerCase());
          });

          viewByOpts = availableSegments.map(function (s) {
            querySegments[s.toLowerCase()] = segments[s];
            return s.charAt(0).toUpperCase() + s.slice(1);
          });
        })();
      }

      var opt = viewByOpts[0];
      if (this.tableParams.default_view_by) {
        var defaultValue = this.tableParams.default_view_by;
        opt = defaultValue.charAt(0).toUpperCase() + defaultValue.slice(1);
      }

      this.set('viewByOpts', viewByOpts);
      this.set('selectedViewBy', opt);
      if (opt !== undefined) {
        this.tableParams.object_id = this.get('querySegments')[opt.toLowerCase()];
      }
      this.set('displayViewBy', viewByOpts.length > 1);
    },

    setDataConverter: function setDataConverter() {
      var dataConverter = _dueDashboardObjectsReportsDataConverterTableDataConverterFactory['default'].create({ type: this.tableParams.get('type') });
      if (dataConverter) {
        this.dataConverter = dataConverter.getConverter();
      }
    },

    setTableParams: function setTableParams() {
      if (this.tableParams == null) {
        this.tableParams = _dueDashboardObjectsReportsDueReportTableParams['default'].create(this.get('content'));
      }

      if (this.tableParams.pagination != undefined) {
        this.tableParams.setPagination(this.get('pageIndex'), this.limit.key);
        this.set('pagination', true);
      }

      var dashboardQueryFilters = this.get('filters.queryFilters').filter,
          reportQueryFilters = this.get('reportFilters');
      this.tableParams.setFilters(this.get('filters.mergeQueryFilters'), dashboardQueryFilters, reportQueryFilters);

      this.tableParams.setDateRange(dashboardQueryFilters.start_date, dashboardQueryFilters.end_date);
      this.tableParams.setQuerySegments(this.get('querySegments'));
      this.tableParams.setTimezone(_dueDashboardHelpersCurrentTimezone['default'].compute());
      return this.tableParams;
    },

    filteredData: computed('content_data.[]', 'filters.tags.[]', 'tableParams.type', function () {
      var _this3 = this;

      var type = this.get('tableParams.type');
      var data = this.get('content_data');

      if (['tags', 'tag-breakdown'].includes(type)) {
        (function () {
          var tags = _this3.get('filters.tags');

          if (tags.length > 0) {
            data = data.map(function (page) {
              return _this3._filterTags(page, tags);
            });
          }
        })();
      }
      return data;
    }),

    _filterTags: function _filterTags(rows, tags) {
      return rows.filter(function (row) {
        var labelElem = row.find(function (e) {
          return e.type === 'label-with-sub';
        });

        if (!labelElem) {
          return false;
        }

        var elemName = labelElem.value.original_name;

        return tags.any(function (tag) {
          var filterName = tag.get('name');

          if (elemName.indexOf('>') === -1) {
            return elemName === filterName;
          } else {
            return elemName.startsWith(filterName);
          }
        });
      });
    },

    data: computed('filteredData.[]', 'pageIndex', function () {
      var data = this.get('filteredData');

      return this.dataConverter.formatData({
        tableParams: this.tableParams,
        data: data[this.get('pageIndex') - 1 || 0],
        selectedViewBy: this.get('selectedViewBy'),
        currentLanguage: this.get('currentLanguage'),
        i18n: this.get('i18n')
      });
    }),

    viewBy: computed('', function () {
      return this.tableParams.view_type;
    }),

    displayDownload: computed('tableParams.view_type', 'tableParams.type', function () {
      return this.tableParams.view_type === 'segents' || this.tableParams.type === 'tag-breakdown';
    }),

    fetchData: function fetchData() {
      var _this4 = this;

      var tableParams = this.tableParams;
      if (tableParams == undefined) {
        this.set('isEmpty', true);
        return;
      }
      this.set('isLoading', true);
      this.set('isEmpty', false);
      this.get('dataQuery').fetchData(tableParams).then(function (response) {
        var data = _this4.get('content_data') || [];
        _this4.set('content_data', data.concat([response.get('content_data')]));
        _this4.set('pageCount', response.get('page_count') || 1);
        _this4.set('isLoading', false);
        _this4.set('isEmpty', data.concat(response.get('content_data')).length === 0);
      })['catch'](function (error) {
        // Empty state when catched errors
        _this4.set('isLoading', false);
        _this4.set('isEmpty', true);
        console.error(error);
      });
    },

    _closeExportModal: function _closeExportModal() {
      this.set('emptyModalIsOpened', false);
      // this.set('nameModalIsOpened', false);
      this.set('exportModalIsOpened', false);
    },

    _closeProgressModal: function _closeProgressModal() {
      this.set('progressModalIsOpened', false);
    },

    _formatTableParamsToExport: function _formatTableParamsToExport(exportConfig) {
      console.log('formatting start');
      console.log(this.tableParams, exportConfig);

      this.tableParams.properties.forEach(function (property, index) {
        property.to_export = exportConfig.custom_columns_list[index].selected || exportConfig.content_option === 'all';
      });
      console.log('end', this.tableParams.toJson());
      return this.tableParams;
    },

    actions: {
      triggerReload: function triggerReload() {
        this.toggleProperty('reload');
      },

      changePagination: function changePagination(limit) {
        this.set('content_data', []);
        this.set('pageIndex', 1);
        this.tableParams.setPagination(1, limit.key);
        this.fetchData();
      },

      previousPage: function previousPage() {
        this.set('pageIndex', this.get('pageIndex') - 1);
        this.tableParams.page_number = this.get('pageIndex');
      },

      nextPage: function nextPage() {
        this.set('pageIndex', this.get('pageIndex') + 1);
        this.tableParams.page_number = this.get('pageIndex');
        if (this.get('content_data.length') < this.get('pageIndex')) {
          this.fetchData();
          this.element.scrollIntoView({ behavior: "smooth" });
        }
      },

      changeViewBy: function changeViewBy(selectedViewBy) {
        this.tableParams.object_id = this.get('querySegments')[selectedViewBy.toLowerCase()];
        this.tableParams.page_number = 1;
        this.set('content_data', []);
        this.set('pageIndex', 1);
        this.set('selectedViewBy', selectedViewBy);
        this.fetchData();
        this.element.scrollIntoView({ behavior: "smooth" });
      },

      openExportModal: function openExportModal() {
        if (!this.get('isEmpty') && !this.get('isLoading')) {
          this.set('exportModalIsOpened', true);
        } else {
          this.set('emptyModalIsOpened', true);
        }
      },

      // openNameModal() {
      //   if (!this.get('isEmpty') && !this.get('isLoading')) {
      //   if (!this.get('isEmpty') && !this.get('isLoading')) {
      //     let name = 'export_' + this.get('currentAccount.content.company.name') + '_diduenjoy';
      //     this.set('newName', name);
      //     this.set('newFormat', 'xlsx');
      //     this.set('fileContent', 'displayed_view');
      //     this.set('nameModalIsOpened', true);
      //   }
      //   else {
      //   }
      // },

      closeExportModal: function closeExportModal() {
        this._closeExportModal();
      },

      'export': function _export(exportConfig) {
        var _this5 = this;

        var formattedContent = this._formatTableParamsToExport(exportConfig);
        var content = JSON.stringify(formattedContent.toJson());
        var export_type = exportConfig.export_format;
        var content_type = this.get('fileContent');
        var file_file_name = this.get('newName');

        var exportModel = ({
          segments: 'segment-table-export-request',
          'tag-breakdown': 'tag-breakdown-export-request'
        })[this.tableParams.type];

        var promise = this.get('store').createRecord(exportModel, {
          content: content,
          export_type: export_type,
          content_type: content_type,
          file_file_name: file_file_name
        }).save();

        this.set('export_promise', promise);
        promise.then(function (export_request) {
          // this.set('nameModalIsOpened', false);
          _this5.send('closeExportModal');
          _this5.set('progressModalIsOpened', true);
        });
      },

      changeFormat: function changeFormat(format) {
        this.set('newFormat', format);
      },

      changeFileContent: function changeFileContent(fileContent) {
        this.set('fileContent', fileContent);
      },

      goToHistory: function goToHistory() {
        this.get('redirectAction')('companies.exports.history');
        this._closeProgressModal();
      },

      closeProgressModal: function closeProgressModal() {
        this._closeProgressModal();
      },

      validateExport: function validateExport(exportConfig) {
        this.send('export', exportConfig);
      }
    }
  });
});