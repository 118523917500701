define("due-dashboard/templates/components/v2/-single-select", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 14,
            "column": 0
          }
        },
        "moduleName": "due-dashboard/templates/components/v2/-single-select.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2, "class", "due-typo-default");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "arrow-container");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("i");
        dom.setAttribute(el3, "class", "tio-chevron_down");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(4);
        morphs[0] = dom.createAttrMorph(element0, 'class');
        morphs[1] = dom.createAttrMorph(element0, 'onclick');
        morphs[2] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
        morphs[3] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["selected-value-container ", ["subexpr", "if", [["get", "disabled", ["loc", [null, [1, 42], [1, 50]]], 0, 0, 0, 0], "disabled"], [], ["loc", [null, [1, 37], [1, 63]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "onclick", ["subexpr", "action", ["toggleDropdownOpen"], [], ["loc", [null, [null, null], [1, 104]]], 0, 0], 0, 0, 0, 0], ["content", "selectedValue", ["loc", [null, [2, 33], [2, 50]]], 0, 0, 0, 0], ["inline", "component", ["v2/-dropdown"], ["options", ["subexpr", "@mut", [["get", "options", ["loc", [null, [8, 10], [8, 17]]], 0, 0, 0, 0]], [], [], 0, 0], "displayKey", ["subexpr", "@mut", [["get", "displayKey", ["loc", [null, [9, 13], [9, 23]]], 0, 0, 0, 0]], [], [], 0, 0], "onSelect", ["subexpr", "action", ["onSelect"], [], ["loc", [null, [10, 11], [10, 30]]], 0, 0], "isOpen", ["subexpr", "@mut", [["get", "dropdownIsOpen", ["loc", [null, [11, 9], [11, 23]]], 0, 0, 0, 0]], [], [], 0, 0], "onClickOutside", ["subexpr", "action", ["toggleDropdownOpen"], [], ["loc", [null, [12, 17], [12, 46]]], 0, 0]], ["loc", [null, [7, 0], [13, 2]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});